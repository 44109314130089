// Imports
import { App } from "./app.class";

// Klasse Modal
export class Modal {

    constructor($ele) {

        this.$container = $ele;
        this.id = $ele.id;
        this.$overlay = this.$container.querySelector(".overlay");
        this.$dialog = this.$container.querySelector(".dialog");
        this.$closeBtns = this.$container.querySelectorAll("*[x-close]");

        // Klick auf Öffnungs-Links
        document.querySelectorAll(`*[x-open-slidemodal="${this.id}"]`).forEach($link => {
            $link.addEventListener("click", event => {
                event.preventDefault();
                this.open();
            });
        });

        // Klick auf Schliessen-Links
        document.querySelectorAll(`*[x-close-slidemodal="${this.id}"]`).forEach($link => {
            $link.addEventListener("click", event => {
                event.preventDefault();
                this.close();
            });
        });
    }

    // öffnen
    open() {
        if(!this.$container.classList.contains("open")) {

            // Alle schliessen
            this.closeAll();

            // Event-Listener
            this.close = this.close.bind(this);
            this.$overlay.addEventListener("click", this.close);
            document.addEventListener("keyup", this.close);
            if(this.$closeBtns) {
                this.$closeBtns.forEach($btn => {
                    $btn.addEventListener("click", this.close);
                });
            }

            // Body-Scrolling fixieren
            const scrollY = window.scrollY;
            this.$container.addEventListener('transitionend', event => {
                document.body.style.position = 'fixed';
                document.body.style.top = "-"+scrollY+"px";
            }, { once: true });

            // Event-Trigger
            this.$container.dispatchEvent(new CustomEvent('slidemodal-open', { detail: {} }));

            // Hidden-Attribut
            this.$container.removeAttribute("hidden");
            const reflow = this.$container.offsetHeight;

            // Klasse hinzufügen
            this.$container.classList.add("open");

            // Focus auf erstes Eingabefeld
            const $input = this.$dialog.querySelector("input,textarea");
            if($input) {
                setTimeout(()=>{
                    $input.focus();
                }, 300);
            }
        }
    }

    // schliessen
    close(event=null) {
        if(this.$container.classList.contains("open")) {

            if(event) {
                if(event.type === "click") {
                    event.preventDefault();
                }
                else if(event.type === "keyup") {
                    if(event.key !== "Escape") {
                        return;
                    }
                }
            }

            // Event-Listener entfernen
            this.$overlay.removeEventListener("click", this.close);
            document.removeEventListener("keyup", this.close);
            if(this.$closeBtns) {
                this.$closeBtns.forEach($btn => {
                    $btn.removeEventListener("click", this.close);
                });
            }

            // Body-Scrolling herstellen
            let scrollY = document.body.style.top;
            scrollY = parseInt(scrollY || '0');
            document.documentElement.style.scrollBehavior = "auto";
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, scrollY * -1);
            document.documentElement.style.scrollBehavior = "smooth";

            // Hidden-Attribut
            this.$container.addEventListener('transitionend', event => {
                this.$container.setAttribute("hidden", true);
            }, { once: true });

            // Klasse entfernen
            this.$container.classList.remove("open");

            // Event-Trigger
            this.$container.dispatchEvent(new CustomEvent('slidemodal-close', { detail: {} }));
        }
    }

    // alle schliessen
    closeAll() {
        window.SlideModals.forEach(m => {
            m.close();
        });
    }
}

// alle SlideModals initialisieren
App.domready(() => {
    window.Modals = [];
    const $modals = document.querySelectorAll(".modal");
    $modals.forEach($ele => {
        const id = $ele.id;
        window.Modals[id] = new Modal($ele);
    });
});