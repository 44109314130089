import { App } from './app.class';

export class Page {
    constructor() {

        // Das Absenden des Template-Formulars unterdrücken
        const $form = document.querySelector('#formcard form');
        if($form) {
            $form.addEventListener('submit', e => {
                e.preventDefault();
            });
        }

    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('formtemplate')) {
        new Page();
    }
});