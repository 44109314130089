import { App } from './app.class';
import { Fetch } from '../fetch.class';
import ClipboardJS from 'clipboard';

export class Page {

    constructor() {

        // Sendeadresse anfordern
        const $sectionConnection = document.querySelector("section#connection");
        if($sectionConnection) {

            // Elemente
            const $contentForm = $sectionConnection.querySelector("#content-form");
            const $form = $contentForm.querySelector("form");
            const $inputFormname = $form.querySelector("input[name=formname]");
            const $inputEmail = $form.querySelector("input[name=email]");
            const $inputTerms = $form.querySelector("input[name=terms_consent]");
            const $switchTerms = $form.querySelector(".switch");
            const $btnSubmit = $form.querySelector("button[type=submit]");
            const $toastError = $contentForm.querySelector(".toast.error");
            const $toastErrorText = $toastError.querySelector("p");
            const $contentSuccess = $sectionConnection.querySelector("#content-success");
            const $contentSuccessText = $contentSuccess.querySelector(".success_msg");
            const $formAddress = $contentSuccess.querySelector(".address pre");
            const $btnCopy = $contentSuccess.querySelector(".address button");
            const linksReplacePanelUrl = document.querySelectorAll(".replace-panel-url");

            // Status von Sende-Button aktualisieren
            const updateSubmitBtnStatus = function() {

                let isValid = false;
                if($inputFormname.checkValidity() && $inputEmail.checkValidity()) {
                    if(!$switchTerms || $switchTerms.classList.contains("on")) {
                        isValid = true;
                    }
                }

                if(!isValid) {
                    $btnSubmit.setAttribute("disabled", "");
                } else {
                    $btnSubmit.removeAttribute("disabled");
                }
            };

            // Sende-Button aktivieren
            $inputFormname.addEventListener('keyup', event => {
                updateSubmitBtnStatus();
            });
            $inputFormname.addEventListener('change', event => {
                updateSubmitBtnStatus();
            });
            $inputEmail.addEventListener('keyup', event => {
                updateSubmitBtnStatus();
            });
            $inputEmail.addEventListener('change', event => {
                updateSubmitBtnStatus();
            });
            if($switchTerms) {
                $switchTerms.addEventListener('switch-change', event => {
                    updateSubmitBtnStatus();
                });
            }
            updateSubmitBtnStatus();

            // Absenden
            $form.addEventListener('submit', event => {

                event.preventDefault();
                const $form = event.currentTarget;

                // Sendebutton deaktivieren
                App.setSubmitBtnLoading($form);

                // Daten
                const formData = new FormData($form);

                // Fehlermeldung ausblenden
                $toastError.classList.add("hidden");

                // Senden
                new Fetch().post(App.getUrl(App.getLang()+'/submit_formrequest'), formData)
                .then(response => {

                    // Erfolgsanzeige
                    $contentSuccessText.innerHTML = response.info_msg;
                    $formAddress.textContent = response.form_submission_url;
                    linksReplacePanelUrl.forEach($link => {
                        $link.href = response.form_panel_url
                    });
                    $contentForm.classList.add("hidden");
                    $contentSuccess.classList.remove("hidden");

                    // Ziel bei Matomo triggern
                    if(response.new_account == true) {
                        _paq.push(['trackGoal', 2]);
                    }
                })
                .catch(error => {
                    $toastErrorText.innerHTML = error.message;
                    $toastError.classList.remove("hidden");
                })
                .finally(() => {

                    // Sendebutton zurücksetzen
                    App.setSubmitBtnActive($form);
                });
            });

            // Kopierfunktion
            const templateClipboard = new ClipboardJS($btnCopy, {
                target: function(trigger) {
                    return $formAddress;
                }
            });
            templateClipboard.on('success', event => {
                event.clearSelection();
                const msg = event.trigger.dataset.successmsg ?? "Copied!";
                App.showMsg(msg, "success");
            });
        }
    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('formrequest')) {
        new Page();
    }
});