import { App } from './app.class';
import { Fetch } from '../fetch.class';
import ClipboardJS from 'clipboard';

export class Page {

    constructor() {

        // Sendeverbindung einrichten
        const $sectionConnection = document.querySelector("section#connection");
        if($sectionConnection) {

            // Elemente
            const $contentForm = $sectionConnection.querySelector("#content-form");
            const $form = $contentForm.querySelector("form");
            const $inputEmail = $form.querySelector("input[name=email]");
            const $inputTerms = $form.querySelector("input[name=terms_consent]");
            const $switchTerms = $form.querySelector(".switch");
            const $btnSubmit = $form.querySelector("button[type=submit]");
            const $toastError = $contentForm.querySelector(".toast.error");
            const $toastErrorText = $toastError.querySelector("p");
            const $contentSuccess = $sectionConnection.querySelector("#content-success");
            const $contentSuccessText = $contentSuccess.querySelector(".success_msg");
            const $sectionHtml = document.querySelector("section#html");
            const $code = $sectionHtml.querySelector(".code");
            const $btnCopy = $sectionHtml.querySelector("button.copy");
            const $btnDownload = $sectionHtml.querySelector("button.download");
            const $btnPanel = $sectionHtml.querySelector("button.panel");

            // Status von Sende-Button aktualisieren
            const updateSubmitBtnStatus = function() {

                let isValid = false;
                if($inputEmail.checkValidity() && $switchTerms.classList.contains("on"))
                    isValid = true;

                if(!isValid) {
                    $btnSubmit.setAttribute("disabled", "");
                    $btnCopy.setAttribute("disabled", "");
                    $btnPanel.setAttribute("disabled", "");
                } else {
                    $btnSubmit.removeAttribute("disabled");
                }
            };

            // Sende-Button aktivieren
            if($inputEmail) {
                $inputEmail.addEventListener('keyup', event => {
                    updateSubmitBtnStatus();
                });
                $inputEmail.addEventListener('change', event => {
                    updateSubmitBtnStatus();
                });
                $switchTerms.addEventListener('switch-change', event => {
                    updateSubmitBtnStatus();
                });
                updateSubmitBtnStatus();
            }

            // Absenden
            $form.addEventListener('submit', event => {

                event.preventDefault();
                const $form = event.currentTarget;

                // Sendebutton deaktivieren
                App.setSubmitBtnLoading($form);

                // Daten
                const formData = new FormData($form);

                // Fehlermeldung ausblenden
                $toastError.classList.add("hidden");

                // Senden
                new Fetch().post(App.getUrl(App.getLang()+'/submit_formsetup'), formData)
                .then(response => {

                    // Erfolgsanzeige
                    $contentSuccessText.innerHTML = response.info_msg;
                    $btnPanel.dataset.href = response.form_panel_url
                    $btnDownload.dataset.href = response.sourcecode_download_url

                    // Sendeadresse in Code einfügen
                    const codeFragments = $code.querySelectorAll("code *");
                    codeFragments.forEach($fragment => {
                        if($fragment.textContent == '"SENDING ADDRESS"') {
                            $fragment.textContent = '"'+response.form_submission_url+'"';
                        }
                    });

                    $contentForm.classList.add("hidden");
                    $contentSuccess.classList.remove("hidden");

                    // Code aktivieren
                    $code.classList.remove('blurred');
                    $btnCopy.removeAttribute("disabled");
                    $btnPanel.removeAttribute("disabled");
                    $btnDownload.removeAttribute("disabled");

                    // Conversion bei Google registrieren
                    window.gtag_report_conversion();

                    // Ziel bei Matomo triggern
                    if(response.new_account == true) {
                        _paq.push(['trackGoal', 4]);
                    }
                })
                .catch(error => {
                    $toastErrorText.innerHTML = error.message;
                    $toastError.classList.remove("hidden");
                })
                .finally(() => {

                    // Sendebutton zurücksetzen
                    App.setSubmitBtnActive($form);
                });
            });

            // Kopierfunktion
            const templateClipboard = new ClipboardJS($btnCopy, {
                text: function(trigger) {
                    const $code = document.querySelector("section#html .code > pre > code");
                    let text = $code.textContent.trim();
                    return text;
                }
            });
            templateClipboard.on('success', event => {
                event.clearSelection();
                const msg = event.trigger.dataset.successmsg ?? "Copied!";
                App.showMsg(msg, "success");
            });

            // Button Panel
            $btnPanel.addEventListener('click', e => {

                // ist deaktiviert
                if($btnPanel.hasAttribute('disabled')) {
                    return;
                }

                // URL aufrufen
                const url = $btnPanel.dataset.href;
                window.open(url, "_self");
            });

            // Button Download
            $btnDownload.addEventListener('click', e => {

                // ist deaktiviert
                if($btnDownload.hasAttribute('disabled')) {
                    return;
                }

                // URL aufrufen
                const url = $btnDownload.dataset.href;
                window.open(url, "_self");
            });
        }
    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('formsetup')) {
        new Page();
    }
});