import { App } from './app.class';

export class Page {

    constructor() {

        // Features Aufklappbereiche
        const $features = document.querySelectorAll("dl.features > div");
        if($features.length > 0) {

            $features.forEach($feature => {

                const $btn = $feature.querySelector("button");
                const $dd = $feature.querySelector("dd");

                $btn.addEventListener('click', event => {

                    // ist geöffnet
                    if($feature.classList.contains('open')) {

                        $feature.classList.add('closing');
                        $btn.setAttribute('aria-expanded', 'false');

                        $dd.style.maxHeight = $dd.scrollHeight+"px";
                        setTimeout(() => {
                            $dd.style.maxHeight = "0px";
                        }, 1);
                    }

                    // geschlossen
                    else {

                        $feature.classList.add('open');
                        $btn.setAttribute('aria-expanded', 'true');

                        $dd.style.maxHeight = "0px";
                        setTimeout(() => {
                            $dd.style.maxHeight = $dd.scrollHeight+"px";
                        }, 1);
                    }
                });

                $dd.addEventListener('transitionend', event => {

                    // wurde geschlossen
                    if($feature.classList.contains('closing')) {
                        $feature.classList.remove('open');
                        $feature.classList.remove('closing');
                        $btn.setAttribute('aria-expanded', 'false');
                    }

                    // wurde geöffnet
                    else if($feature.classList.contains('open')) {
                        $dd.style.maxHeight = '';
                    }
                });
            });
        }

    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('product')) {
        new Page();
    }
});