import Toastify from 'toastify-js';

// Klasse App
export class App {

    // Prüft ob DOM geladen und führt Callback aus
    static domready(fn) {
        if (document.readyState != 'loading'){
            fn();
        } else {
            document.addEventListener('DOMContentLoaded', fn);
        }
    }

    // Liefert Website-URL
    static getUrl(path='', get_parameter=[]) {

        // Parameter initialisieren
        if(typeof path === "undefined")             path = "";
        if(typeof get_parameter === "undefined")    get_parameter = [];

        // Parameter prüfen
        if(typeof path !== "string")                return false;
        if(typeof get_parameter !== "object")       return false;

        // Parameter bereinigen
        path = path.trim();

        // URL zusammenstellen
        let url = window.location.protocol + "//" + window.location.hostname;

        if(path.length > 0) {
            url += '/'+path;
        }
        if(typeof get_parameter === "object") {
            url += '?';
            Object.entries(get_parameter).forEach(([key, value]) => {
                value = value.toString();
                if(key.length > 0 && value.length > 0) {
                    url += key.trim() + '=' + value.trim() + '&';
                }
            });
            url = url.substring(0, url.length - 1);
        }

        // liefern
        return url;
    }

    // Weiterleitung zu URL
    static gotoUrl(url) {
        window.location = url;
    }

    // Liefert Code der aktuellen Sprache
    static getLang() {
        const $html = document.querySelector('html');
        const lang = $html.getAttribute('lang');
        return lang;
    }

    // Sendebuttons eines Formulars auf Ladestatus setzen
    static setSubmitBtnLoading($form) {
        const $btns = $form.querySelectorAll('button[type=submit]');
        $btns.forEach($btn => {
            $btn.classList.add('loading');
            $btn.setAttribute('disabled', true);
        });
    }

    // Sendebuttons eines Formulars auf Aktiv setzen
    static setSubmitBtnActive($form) {
        const $btns = $form.querySelectorAll('button[type=submit]');
        $btns.forEach($btn => {
            $btn.classList.remove('loading');
            $btn.removeAttribute('disabled');
        });
    }

    static showMsg(msg, className, duration=3000) {
        Toastify({
            text: msg,
            gravity: "bottom",
            position: "right",
            duration: duration,
            className: className
        }).showToast();
    }

}