// Libs
const axios = require('axios').default;

// Klasse Fetch
export class Fetch {

    #config = {
        headers: {
            'Accept': 'application/json',
            'x-requested-with': 'xmlhttprequest',
        }
    };

    constructor(config) {

        // Konfiguration zusammenstellen
        if(config && typeof config === "object") {
            this.#config = { ...this.#config, ...config };
        }

        return this;
    }

    async get(endpoint) {
        return this.#send("get", endpoint, null);
    }

    async put(endpoint, body) {
        return this.#send("put", endpoint, body);
    }

    async post(endpoint, body) {
        return this.#send("post", endpoint, body);
    }

    async delete(endpoint, body) {
        return this.#send("delete", endpoint, body);
    }

    async #send(method, endpoint, body) {

        let options = {
            method: method,
            url: endpoint,
        };
        if(body) {
            options.data = body;
        }
        options = { ...this.#config, ...options };

        return new Promise((resolve, reject) => {

            axios.request(options)
            .then(response => {

                // No Success
                if(response.data?.success !== true) {
                    let msg = response.data.error_msg ?? "Unknown error";
                    reject({ "message": msg, "response": response });
                }

                // Erfolgreich
                else resolve(response.data);
            })
            .catch(error => {

                if(typeof error !== "object") {
                    error = { message: error };
                }

                if (error.response) {

                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);

                    error.message = error.message ?? "Server error";

                } else if (error.request) {

                    // The request was made but no response was received
                    console.log(error.request);

                    error.message = error.message ?? "No response";
                }

                reject(error);
            });

        });
    }
}