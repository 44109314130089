import { App } from './app.class';

export class Header {
    constructor() {

        const $header = document.querySelector("header");

        // Mobile-Menu open/close
        const $btnsMob = document.querySelectorAll("header .btn-mob");
        if($btnsMob) {
            $btnsMob.forEach($btn => {
                $btn.addEventListener("click", event => {
                    event.stopPropagation();

                    if($header.classList.contains("mob-open")) {
                        let scrollY = document.body.style.top;
                        scrollY = parseInt(scrollY || '0');

                        document.documentElement.style.scrollBehavior = "auto";
                        document.body.style.position = '';
                        document.body.style.top = '';

                        window.scrollTo(0, scrollY * -1);
                        $header.classList.remove("mob-open");
                        document.documentElement.style.scrollBehavior = "smooth";
                    } else {

                        const scrollY = window.scrollY;
                        setTimeout(() => {
                            document.body.style.position = 'fixed';
                            document.body.style.top = "-"+scrollY+"px";
                        }, "200");
                        $header.classList.add("mob-open");
                    }
                });
            });
        }

        // Header-Bar gets sticky
        window.addEventListener('scroll', function() {
            const scrollpos = window.scrollY;
            const scrollposCss = parseInt(document.body.style.top || '0');
            if(scrollpos > 200 || Math.abs(scrollposCss) > 200) {
                $header.classList.add("is-fixed");
            } else {
                if(!$header.classList.contains("mob-open")) {
                    $header.classList.remove("is-fixed");
                }
            }
        });
    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('header')) {
        new Header();
    }
});