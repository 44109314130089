import { App } from './app.class';
import { Fetch } from '../fetch.class';

export class Footer {
    constructor() {
        const $footer = document.querySelector("footer");

        // Einstellungen
        const message_modal_id = "slidemodal-msg";
        const message_url = "https://form.taxi/s/cp6j50yp";

        // Nachricht senden
        const $modalMessage = document.querySelector('#'+message_modal_id);
        if($modalMessage) {

            const $form = $modalMessage.querySelector('form');
            const $submitBtn = $form.querySelector("button[type=submit]");
            const $inputs = $form.querySelectorAll("input.inpt, textarea.inpt");

            // Status von Sendebutton aktualisieren
            const updateSubmitBtnStatus = function() {
                var isValid = true;
                $inputs.forEach($i => {
                    if($i.checkValidity() === false) {
                        isValid = false;
                    }
                });
                if(!isValid)
                    $submitBtn.setAttribute("disabled", "");
                else
                    $submitBtn.removeAttribute("disabled");
            };

            // Sendebutton aktivieren
            $inputs.forEach($input => {
                $input.addEventListener('keyup', event => {
                    updateSubmitBtnStatus();
                });
                $input.addEventListener('change', event => {
                    updateSubmitBtnStatus();
                });
            });
            updateSubmitBtnStatus();

            // Absenden
            $form.addEventListener('submit', event => {
                event.preventDefault();

                const $form = event.currentTarget;
                const formData = new FormData($form);

                // Sendebutton deaktivieren
                App.setSubmitBtnLoading($form);

                // Senden
                new Fetch().post(message_url, formData)
                .then(response => {

                    // Modal schliessen
                    window.SlideModals[message_modal_id].close();

                    // Erfolgsmeldung anzeigen
                    const $modal = document.querySelector("#"+message_modal_id);
                    const text = $modal.getAttribute('data-msg-success');
                    App.showMsg(text, "success");

                    // Formular zurücksetzen
                    $form.reset();
                })
                .catch(error => {
                    App.showMsg(error.message ?? "Unknown error", "error", 5000);
                })
                .finally(() => {

                    // Sendebutton zurücksetzen
                    App.setSubmitBtnActive($form);
                });
            });
        }
    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('footer')) {
        new Footer();
    }
});